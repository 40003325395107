Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ApplePayIntegration";
exports.labelBodyText = "ApplePayIntegration Body";

exports.btnExampleTitle = "CLICK ME";

exports.amountPlaceHolder = "Enter Pay Amount";
exports.btnApplePayLabel = "Pay";
exports.notSupportMessage ="Not Support on device";
exports.paymentFaileMsg = 'Payment will be fail, try again';
exports.errorMsg = 'Some thing went wrong, please try again';

exports.applePay = "apple-pay";
exports.golavi = "Golavi";
exports.precision = 2;
exports.minValue = 0;

exports.visa = "visa";
exports.masterCard = "mastercard";
exports.amex = "amex";

exports.numberDotRex = /^\d{0,8}\.?\d{0,2}$/;

exports.merchantIdentifier = "merchant.org.reactjs.native.example.GoLavi";
// Customizable Area End